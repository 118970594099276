import React from "react";
import { LaunchAppButton } from "../components/LaunchAppButton";
import LinkButton from "../components/Button";
import { cn } from "../utils/classNames";
import { motion } from "framer-motion";
import Section from "../components/Section";
import { APP_URL, ARB_APP_URL } from "../constants";

const Home = () => {
  return (
    <Section id="home" className="overflow-hidden max-h-fit">
      {/* gradiant ring image */}
      <img
        className="absolute z-10 top-0 left-0 w-full h-full"
        src="/homeBgGradiantRing.png"
        alt="bg-ring-home"
      />
      {/* gradiant ellipse image */}
      <img
        className="absolute z-10 top-0 left-0 w-full h-full"
        src="/homeBgGradiantEllipse.png"
        alt="bg-ring-home"
      />
      {/* gradiant circle image */}
      <img
        className="absolute z-10 top-0 left-0 w-full h-full"
        src="/homeBgGradiantCircle.png"
        alt="bg-ring-home"
      />
      {/* gradiant bars image */}
      <motion.div
        className="absolute w-2/3 bottom-[47%] flex h-1/2"
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: 0.5, height: "50%" }}
        transition={{
          duration: 0.8,
          delay: 0.6,
          ease: [0, 1, 1, 1.01],
        }}
      >
        <img
          className="absolute top-0 left-0 w-full h-full"
          src="/homeBgGradiantBars.png"
          alt="bg-bars-home"
        />
      </motion.div>
      {/* Text and button section */}
      <div className="relative z-20 flex flex-col justify-center items-center md:w-1/2 bottom-[150px]">
        <span
          className={cn(
            "inline-block bg-titleGradient text-transparent text-center bg-clip-text font-normal",
            "text-base lg:text-[32px] mb-6 lg:leading-relaxed"
          )}
        >
          The Social Trading Protocol
        </span>
        <span className="lg:text-[64px] lg:leading-[70px] font-medium text-white text-center text-[32px] leading-[35px]">
          Copytrading Evolved
        </span>
        <div className="flex flex-col text-white/80 items-center gap-5 mt-10">
          Launch STFX on:
          <div className="flex gap-5">
            <LaunchAppButton url={APP_URL} img="/icons/SOL.svg">
              Solana
            </LaunchAppButton>
            <LaunchAppButton url={ARB_APP_URL} img="/icons/ARB.svg">
              Arbitrum
            </LaunchAppButton>
          </div>
        </div>
      </div>
      {/* bottom trade page image */}
      <motion.div
        className={cn(
          "absolute opacity-70",
          "min-w-[1300px] w-full h-2/3 bottom-0"
        )}
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{
          duration: 0.5,
          ease: [0, 1, 1, 1.01],
        }}
      >
        <img src="/homeBgImage.png" className="h-full" alt="bg-home" />
      </motion.div>
    </Section>
  );
};

export default Home;
