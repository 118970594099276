import { cn } from "../utils/classNames";
import LinkCard from "../components/Card/LinkCard";
import { APP_URL } from "../constants";

const EnterApp = () => {
  const linkCardContent = [
    {
      title: "Decentralized",
      href: APP_URL,
    },
    {
      title: "Wide variety of Assets",
      href: `${APP_URL}/trade`,
    },
    {
      title: "Subscriptions",
      href: `${APP_URL}`,
    },
  ];
  return (
    <div
      className="flex-col flex relative w-full h-full items-center max-h-full lg:h-[827px] grow justify-start pt-24 pb-16 lg:pb-28"
      id="enter-app"
    >
      <div className="flex flex-col max-w-[426px] top-3 gap-[18px] mx-auto mb-20 items-center">
        <span
          className={cn(
            "inline-block bg-titleGradient text-transparent bg-clip-text font-normal",
            "text-base md:text-[22px] mb-2"
          )}
        >
          Social Trading
        </span>
        <span className="text-[32px] lg:text-[64px] text-white font-medium mb-6">
          Enter STFX
        </span>
        <span className="text-sm lg:text-lg text-white/70 flex text-center">
          STFX unites Social and Trading. Trade and earn together with your
          friends and followers.
        </span>
      </div>

      <img
        src="/image/enterAppBg.png"
        className={cn(
          "w-full h-full lg:max-h-[827px] max-h-[367px] absolute transition-all top-0 left-0 duration-500 delay-100"
        )}
        alt="enter-app-bg"
      />

      {/* link cards */}
      <div className="flex gap-3 mt-6 md:px-[144px] flex-col lg:flex-row">
        {linkCardContent.map((item, index) => (
          <LinkCard href={item.href} title={item.title} />
        ))}
      </div>
    </div>
  );
};

export default EnterApp;
