import React from "react";
import LinkButton from "../Button";
import { APP_URL } from "../../constants";
import { cn } from "../../utils/classNames";

type LaunchButtonProps = {
  children: React.ReactNode;
  url: string;
  className?: string;
  img: string;
};

export const LaunchAppButton: React.FC<LaunchButtonProps> = ({
  children,
  url,
  className,
  img,
}) => {
  return (
    <LinkButton
      className={cn(
        "text-black flex items-center bg-green hover:bg-green/80",
        className
      )}
      link={url}
    >
      <img
        src={img}
        alt="launch-app-arrow"
        className="lg:block hidden"
        width={20}
        height={20}
      />
      {children}
    </LinkButton>
  );
};
