import React from "react";
import { LaunchAppButton } from "../LaunchAppButton";
import { cn } from "../../utils/classNames";
import { APP_URL } from "../../constants";

const Navbar = () => {
  const [nav, setNav] = React.useState(false);
  const linkStyle =
    "text-white decoration-transparent text-sm items-center flex cursor-pointer";
  const navbarItems = [
    {
      text: "About",
      href: "#about",
    },
    {
      text: "$STFX",
      href: "#token",
    },
    {
      text: "Learn",
      href: "#social-finance",
    },
    {
      text: "Explore",
      href: "#stats",
    },
  ];

  return (
    <div className="flex w-full py-5 px-8 justify-between z-[100] items-center bg-black/50 backdrop-blur-xl border-b border-white/5 border-solid">
      <span className="hidden lg:flex w-full justify-between">
        {/* main app logo */}
        <div className="flex items-center justify-start">
          <a href="/">
            <img src="/logo/stfx-logo.svg" width={91} height={18} alt="STFX" />
          </a>
        </div>
        {/* menu items */}
        <div className="flex gap-8">
          {navbarItems.map((item, index) => (
            <a
              href={item.href}
              className={linkStyle}
              key={`${index}-${item.text}`}
            >
              {item.text}
            </a>
          ))}
        </div>
        <LaunchAppButton url={APP_URL} img="/icons/SOL.svg">
          Launch App
        </LaunchAppButton>
      </span>
      <span className="flex lg:hidden w-full justify-between">
        {/* main app logo */}
        <div className="flex items-center justify-start">
          <a href="/">
            <img src="/logo/stfx-logo.svg" width={91} height={18} alt="STFX" />
          </a>
        </div>
        {/* menu items */}
        <div className="flex gap-8 items-center">
          <LaunchAppButton url={APP_URL} img="/icons/SOL.svg">
            Launch App
          </LaunchAppButton>
          <span
            className="flex w-8 h-8 items-center cursor-pointer justify-center p-1"
            onClick={() => setNav(!nav)}
          >
            <img
              src="/icons/navHamIcon.svg"
              className="w-full h-full"
              alt="navham"
            />
          </span>
        </div>
      </span>
      <ul
        className={cn(
          "flex flex-col items-center bg-black  w-[100%] h-screen z-50 ease-in-out duration-500 justify-center",
          nav
            ? "fixed lg:hidden right-0 top-0 "
            : "fixed top-0 bottom-0 right-[-100%]"
        )}
      >
        <span
          onClick={() => setNav(false)}
          className="text-white font-extrabold text-2xl absolute top-5 cursor-pointer right-5"
        >
          X
        </span>
        {/* Mobile Navigation Items */}
        {navbarItems.map((item, index) => (
          <a
            key={`${index}-${item.text}`}
            className="p-4 rounded-xl duration-300 cursor-pointer hover:bg-white/10 hover:text-white/80 text-white"
            href={item.href}
          >
            {item.text}
          </a>
        ))}
      </ul>
    </div>
  );
};

export default Navbar;
